




















import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import login from "./login.vue";

@Component({
  components: {
    login
  }
})
export default class Login extends Vue {
  toWX() {
    window.open("http://www.eweixue.com/#/");
  }
}
