












import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getCode } from "@/apis/user";

@Component
export default class GetCodeButton extends Vue {
  @Prop({ required: true }) private mobile!: string;
  private countdown = 60;
  private timer: any = null;

  @Watch("$route")
  onRouteChanged() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      this.countdown = 60;
    }
  }

  async getCode() {
    if (this.mobile.length == 11) {
      await getCode(this.mobile);
      this.$emit("get-code");
      this.startCountdownTimer();
    } else {
      this.$message.warning("请输入正确的手机号");
    }
  }

  startCountdownTimer() {
    this.countdown--;
    if (this.countdown === 0 && this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
      this.countdown = 60;
    } else {
      this.timer = setTimeout(this.startCountdownTimer, 1000);
    }
  }
}
