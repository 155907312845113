import md5 from "blueimp-md5";
import request from "@/utils/request";

/**
 * 获取uuid(二维码登陆)
 */
export function getUUID() {
  const timestamp = Math.round(+new Date() / 1000);
  const randomStr = 100000000000 + Math.floor(Math.random() * 100000000000);
  return request({
    url: "v1/user/auth/get_uuid",
    method: "post",
    data: {
      timestamp,
      random_str: randomStr,
      sign: md5("web" + randomStr + timestamp)
    }
  });
}

/**
 * 二维码登陆验证
 */
export function uuidLogin(uuid: string) {
  const timestamp = Math.round(+new Date() / 1000);
  return request({
    url: "v1/user/auth/login_uuid",
    method: "post",
    data: {
      timestamp,
      uuid
    }
  });
}
export interface LoginRequest {
  mobile: string;
  code: string;
  expand_customer_id?: string;
}

type TokenInfo = {
  is_first_login: "Y" | "N";
} | null;

interface LoginResponse {
  web_token: string;
  timeout: string;
  token_info: TokenInfo;
}

/**
 * 用户手机登录接口
 */
export function login(data: LoginRequest): any {
  return request({
    url: "v1/user/auth/login",
    method: "post",
    data
  });
}

interface GetCodeRequest {
  mobile: string;
  timestamp: number;
  sign: string;
}

/**
 * 获取手机验证码
 */
export function getCode(mobile: string) {
  const timestamp = Math.round(+new Date() / 1000);
  const params: GetCodeRequest = {
    mobile,
    timestamp,
    sign: md5(mobile + "ee7aebd9fd5047b4a35655c93561c6c04" + timestamp)
  };

  return request({
    url: "v1/user/auth/get_code",
    method: "get",
    params
  });
}

export type ITag = {
  id: string;
  name: string;
};

//获取个人信息
export function getInfo(is_all: number) {
  return request({
    url: "v1/user/info/get",
    method: "get",
    params: {
      is_all // 0基础信息1包含省市县
    }
  });
}

/**
 * 设置兴趣标签
 */
export function setUserTags(tags: ITag[]) {
  return request({
    url: "v1/user/info/tags",
    method: "post",
    data: {
      tags
    }
  });
}

/**
 * 验证Uuid(代理端扫码登录)
 * @param action 1:uuid正在被验证 2:uuid验证通过
 */
export function checkUUID(uuid: string, action: number) {
  const timestamp = Math.round(+new Date() / 1000);
  return request({
    url: "v1/user/auth/check_uuid",
    method: "get",
    params: {
      timestamp,
      uuid,
      action
    }
  });
}
