


































































































































import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import { ElForm } from "element-ui/types/form";
import { getUUID, login, uuidLogin } from "@/apis/user";
import QRCode from "qrcode";
import GetCodeButton from "@/components/GetCodeButton.vue";

@Component({
  components: { GetCodeButton },
  filters: {
    // (0=注册中，1=正常，2=注销中)"
    filtersStatus(val: number) {
      enum Type {
        "(注册中)" = 0,
        "(注销中)" = 2
      }
      return Type[val];
    }
  }
})
export default class Login extends Vue {
  private timer: number | null = null;
  private innerVisible: boolean = false;
  private activeName: string = "first";
  private form: object = {
    name: ""
  };
  private tabActiveName = "qr-code";
  private loginForm = {
    mobile: "",
    code: ""
  };
  private orgList: object[] = [];
  private height: any = 0;

  private vaildMobile = (rule: object, vlaue: string, cb: any) => {
    if (this.loginForm.mobile) {
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.mobile)) {
        cb(new Error("手机号码有误，请重填"));
      }
      cb();
    }
  };
  private rules: object = {
    mobile: [
      { required: true, message: "请输入手机号", trigger: "blur" },
      { validator: this.vaildMobile, trigger: "blur" }
    ],
    code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
  };
  private isScan = false;

  // @Watch("tabActiveName")
  private onTabChanged(val: any) {
    this.tabActiveName = val.name;
    if (val.name === "qr-code") {
      this.getUUID();
    } else {
      clearTimeout(this.timer as number);
      this.timer = null;
    }
  }

  created() {
    this.tabActiveName = "qr-code";
    this.$nextTick(() => {
      this.height = (document.querySelector(".bg-img") as any).clientHeight;
    });
    window.onresize = () => {
      return (() => {
        this.height = (document.querySelector(".bg-img") as any).clientHeight;
      })();
    };
    this.getUUID();
  }
  public handleLogin(formName: any) {
    (this.$refs[formName] as ElForm).validate(valid => {
      if (valid) {
        this.login();
      } else {
        return false;
      }
    });
  }
  //手机登录
  private async login() {
    if (!this.loginForm.mobile) {
      this.$message.warning("请输入正确的手机号");
      return false;
    }
    if (!this.loginForm.code) {
      this.$message.warning("请输入验证码");
      return false;
    }
    const { data, message } = await login(this.loginForm);
    if (data) {
      window.location.href = `${process.env.VUE_APP_BASE_PC}login-to?token=${data.web_token}`;
    } else {
      this.$message.warning(message);
    }
  }
  private async getUUID() {
    this.isScan = false;
    const { data } = await getUUID();

    QRCode.toCanvas(
      document.getElementById("qr-code"),
      `${process.env.VUE_APP_BASE_PCAPI}login/pc-code-login?uuid=${data.uuid}`,
      // `${process.env.VUE_APP_BASE_H5}login/pc-code-login?uuid=${uuid}`,
      {
        width: 150
      },
      (error: any) => {}
    );

    this.uuidLogin(data.uuid);
  }

  private async uuidLogin(uuid: string) {
    const data = await uuidLogin(uuid);

    if (data.data) {
      window.location.href = `${process.env.VUE_APP_BASE_PC}login-to?token=${data.data.web_token}`;
    } else if (data.message == "已扫码") {
      this.isScan = true;
    }
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = window.setTimeout(() => {
      this.uuidLogin(uuid);
    }, 1000);
  }
  private beforeDestroy() {
    clearTimeout(this.timer as number);
  }
  async getCode() {
    (this.$refs.code as HTMLElement).focus();
  }
}
