import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { Message } from "element-ui";
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_H5,
  headers: { "content-type": "application/x-www-form-urlencoded" }
});

interface IDefaultParams {
  platform: string;
  /** 时间戳，防止部分环境接口缓存 */
  _t: number;
  web_token?: string;
}

// Request interceptors
instance.interceptors.request.use(
  config => {
    const defaultParams: IDefaultParams = {
      platform: "web",
      _t: +new Date()
    };

    if (config.method === "get") {
      if (!config.params) {
        config.params = defaultParams;
      } else {
        config.params = Object.assign({}, defaultParams, config.params);
      }
    } else if (config.method === "post") {
      if (!config.data) {
        config.data = defaultParams;
      } else {
        config.data = Object.assign({}, defaultParams, config.data);
      }
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  error => {
    // Handle request error here
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错
     */
    // const res = response.data;
    // if (Number(res.code) !== 200) {
    //   return Promise.reject(res.message);
    // } else {
    return Promise.resolve(response.data);
    // }
  }
  // error => {
  //   if (error.message && error.message != "Network Error") {
  //     Message(error.message);
  //   }
  //   return Promise.reject(error);
  // }
);
export default function request(config: AxiosRequestConfig): any {
  return instance(config);
}
